import React, { useState, useEffect, lazy, Suspense } from "react";
import { Seo } from "../components";

const Layout = lazy(async () => await import("../components/layout/layout"));
const Shop = lazy(async () => await import("../components/shop/shop"));

export default function Store({ location }) {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) return null;
  return (
    <Seo title={"Wine and Beer Crafting Supplies and Equipment"}>
      <Suspense fallback={<div></div>}>
        <Layout>
            <Shop
              search={
                location.state && location.state.searchFor
                  ? location.state.searchFor
                  : ""
              }
            />
        </Layout>{" "}
      </Suspense>
    </Seo>
  );
}
